<template>
  <div>
    <BaseTable
      ref="certification-reports-table"
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_CERTIFICATION_REPORT"
      :loading="loading"
      :filters="filters"
      hide-totals
      hide-printer-button
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(job)="data">
        {{ data.value? data.value.code : '' }}
      </template>
      <template #cell(status)="data">
        <StatusBadge
          v-if="data.item.status"
          :status="data.item.status.name"
        />
      </template>
      <template #cell(sale_value)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(pm_cost)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(invoices_issued_to_origin)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(purchase_invoices)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(billing_to_be_issued)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(target_billing_to_source_by_progress)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(minimum_billing_objective_month_per_advancee)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(certification_value_real_month_pm)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
    <CertificationReportsListFilters
      ref="certification-reports-list-filters"
      v-model="filtersVisible"
      @filters-submit="filters = $event"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import CertificationReportsListFilters from '@/components/certification-reports/filters/CertificationReportsListFilters.vue'

export default {
  name: 'CertificationReportsTable',
  components: {
    CertificationReportsListFilters,
    StatusBadge,
    BaseTable,
  },
  data() {
    return {
      loading: false,
      filters: {},
      filtersVisible: false,
      columns: [
        {
          label: 'JOB',
          key: 'job',
          sortable: true,
          thStyle: { 'min-width': '80px' },
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Valor venta',
          key: 'sale_value',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Coste PM',
          key: 'pm_cost',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Facturas Recibidas',
          key: 'purchase_invoices',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Facturación por emitir',
          key: 'billing_to_be_issued',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Objetivo facturación a origen por avance',
          key: 'target_billing_to_source_by_progress',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Objetivo facturación mínima MES por avance',
          key: 'minimum_billing_objective_month_per_advancee',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'Valor Certificación REAL MES PM',
          key: 'certification_value_real_month_pm',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
      ],
    }
  },
  methods: {
    handleClearListFilters() {
      this.filters = {}
      this.$refs['certification-reports-list-filters'].clearFilters()
    },
  },
}
</script>

<style scoped>
.bill-button {
  width: 140px;
}
</style>
